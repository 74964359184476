import { request } from './request'
//引入当前环境的接口域名
import config from '@/config'

// 保存/修改系统消息
function sysMessageSave(data) {
    return request({
        baseURL: process.env.NODE_ENV != 'development' ? config.messageUrl : '',
        url: '/msc/sysMessage/sysMessageSave',
        method: 'POST',
        data
    })
}
// 获取系统消息列表
function selectSysMessageListPage(data) {
    return request({
        baseURL: process.env.NODE_ENV != 'development' ? config.messageUrl : '',
        url: '/msc/sysMessage/selectListPage',
        method: 'POST',
        data
    })
}
// 获取系统消息接收列表
function selectSysMessageReceiveListPage(data) {
    return request({
        baseURL: process.env.NODE_ENV != 'development' ? config.messageUrl : '',
        url: '/msc/sysMessageReceive/selectListPage',
        method: 'POST',
        data
    })
}
// 查找当前的系统消息详情
function getSysMessageData(data) {
    return request({
        baseURL: process.env.NODE_ENV != 'development' ? config.messageUrl : '',
        url: '/msc/sysMessage/getSysMessage',
        method: 'POST',
        data
    })
}
// 获取系统消息未读数量
function getUserUnReadMessageCnt(data) {
    return request({
        url: '/sys/sysMessage/getUserUnReadMessageCnt',
        method: 'POST',
        data: data
    })
}

export default {
    sysMessageSave,
    selectSysMessageListPage,
    selectSysMessageReceiveListPage,
    getSysMessageData,
    getUserUnReadMessageCnt
}
