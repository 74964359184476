import { request } from './request'
import config from '@/config'

// ===== 聊天室 =====

// 获取聊天室分页列表
function selectChatRoomList(data) {
    return request({
        baseURL: process.env.NODE_ENV != 'development' ? config.messageUrl : '',
        url: '/msc/chatRoom/selectListPage',
        method: 'POST',
        data: data
    })
}

// ===== 聊天消息 =====

// 获取聊天消息分页列表
function selectChatMessageList(data) {
    return request({
        baseURL: process.env.NODE_ENV != 'development' ? config.messageUrl : '',
        url: '/msc/chatMessage/selectListPage',
        method: 'POST',
        data: data
    })
}

// 聊天用户

// 更新最后一次聊天时间
function updateUserLastMessageTime(data) {
    return request({
        baseURL: process.env.NODE_ENV != 'development' ? config.messageUrl : '',
        url: '/msc/chatUser/updateUserLastMessageTime',
        method: 'POST',
        data: data
    })
}
// 更换咨询负责人
function changeChatMan(data) {
    return request({
        baseURL: process.env.NODE_ENV != 'development' ? config.messageUrl : '',
        url: '/msc/chatRoom/changeChatMan',
        method: 'POST',
        data: data
    })
}

// AI交互
// 中止AI问答
function stopChatMessageAPI(data) {
    return request({
        baseURL: process.env.NODE_ENV != 'development' ? config.messageUrl : '',
        url: '/msc/aiChat/stopChatMessage',
        method: 'POST',
        data
    })
}

// 获取话题列表
function selectSubjectListPageAPI(data) {
    return request({
        baseURL: process.env.NODE_ENV != 'development' ? config.messageUrl : '',
        url: '/msc/aiChat/selectSubjectListPage',
        method: 'POST',
        data
    })
}

// 删除某条AI话题
function clearSubjectAPI(data) {
    return request({
        baseURL: process.env.NODE_ENV != 'development' ? config.messageUrl : '',
        url: '/msc/aiChat/clearSubject',
        method: 'POST',
        data
    })
}

// 清空话题列表
function clearAllSubjectListAPI(data) {
    return request({
        baseURL: process.env.NODE_ENV != 'development' ? config.messageUrl : '',
        url: '/msc/aiChat/clearAllSubjectList',
        method: 'POST',
        data
    })
}

// 获取话题详情对话
function selectMessageListPageAPI(data) {
    return request({
        baseURL: process.env.NODE_ENV != 'development' ? config.messageUrl : '',
        url: '/msc/aiChat/selectMessageListPage',
        method: 'POST',
        data
    })
}
export default {
    selectChatRoomList,
    selectChatMessageList,
    updateUserLastMessageTime,
    changeChatMan,
    stopChatMessageAPI,
    selectSubjectListPageAPI,
    clearSubjectAPI,
    clearAllSubjectListAPI,
    selectMessageListPageAPI
}
