const { request } = require('./request')
const config = require('@/config')
// 查询活动信息
function getAct(data) {
    return request({
        url: '/sys/act/getAct',
        method: 'POST',
        data
    })
}

// 新增/修改活动信息
function saveAct(data) {
    return request({
        url: '/sys/act/actSave',
        method: 'POST',
        data
    })
}

// 活动数据列表
function selectActList(data) {
    return request({
        url: '/sys/act/selectListPage',
        method: 'POST',
        data
    })
}

// 活动定制化配置，获取
function getActConfig(data) {
    return request({
        url: '/sys/actConfig/getActConfig',
        method: 'POST',
        data
    })
}

// 活动定制化配置，新增或修改
function saveActConfig(data) {
    return request({
        url: '/sys/actConfig/actConfigSave',
        method: 'POST',
        data
    })
}

// 定制类活动表单提交
function actCustomOrderSave(data) {
    return request({
        url: '/sys/actCustomOrder/actCustomOrderSave',
        method: 'POST',
        data
    })
}

// 定制类活动数据列表
function selectCustomListPage(data) {
    return request({
        url: '/sys/actCustomOrder/selectListPage',
        method: 'POST',
        data
    })
}

// 删除当前条定制化活动数据
function actCustomOrderDelete(data) {
    return request({
        url: '/sys/actCustomOrder/actCustomOrderDelete',
        method: 'POST',
        data
    })
}

// 查询当前条定制化活动数据
function getActCustomOrder(data) {
    return request({
        url: '/sys/actCustomOrder/getActCustomOrder',
        method: 'POST',
        data
    })
}

// 校验活动URL是否存在
function checkActUrlAPI(data) {
    return request({
        url: '/sys/act/ifExistsActUrl',
        method: 'POST',
        data
    })
}

// 获取作品审核活动列表
function selectApproveDataListPage(data) {
    return request({
        url: '/sys/actWork/selectApproveDataListPage',
        method: 'POST',
        data
    })
}

// 获取作品列表
function selectActWorkListPage(data) {
    return request({
        url: '/sys/actWork/selectListPage',
        method: 'POST',
        data
    })
}

// 活动作品审核/保存
function collectActWorkSave(data) {
    return request({
        url: '/sys/actWork/actWorkSave',
        method: 'POST',
        data
    })
}

// 活动作品导出数据
function collectActWorkExportData(data) {
    return request({
        url: '/sys/actWork/exportData',
        method: 'POST',
        responseType: 'blob',
        data
    })
}

// 查找作品信息
function getCollectActWorkData(data) {
    return request({
        url: '/sys/actWork/getActWork',
        method: 'POST',
        data
    })
}

// 根据ID查询打卡活动
function getActClock(data) {
    return request({
        url: '/sys/actClock/getActClock',
        method: 'POST',
        data
    })
}

// 查找作品源文件列表
function selectWorkAnnexListPage(data) {
    return request({
        url: '/sys/actWorkFile/selectListPage',
        method: 'POST',
        data
    })
}

// 征集类评审系统
// 新建/修改评审系统
function sysInfoSaveAPI(data) {
    return request({
        url: '/score/sysInfo/sysInfoSave',
        method: 'POST',
        data
    })
}
// 获取评审系统列表数据
function selectSysScoreInfoListPage(data) {
    return request({
        url: '/score/sysInfo/selectListPage',
        method: 'POST',
        data
    })
}
// 查找评审系统信息
function getSysInfoDataAPI(data) {
    return request({
        url: '/score/sysInfo/getSysInfo',
        method: 'POST',
        data
    })
}
// 获取评审系统用户列表
function selectSysUserListPage(data) {
    return request({
        url: '/score/sysUser/selectListPage',
        method: 'POST',
        data
    })
}
// 获取作品评分明细列表
function selectActWorkScoreListPage(data) {
    return request({
        url: '/score/sysScoreDetail/selectActWorkScoreListPage',
        method: 'POST',
        data
    })
}
// 查看评审进度——列表
function selectActWorkScoreRateList(data) {
    return request({
        url: '/score/sysScoreDetail/selectActWorkScoreRateList',
        method: 'POST',
        data
    })
}
// 评审进度——导出
function exportActWorkScoreRateList(data) {
    return request({
        url: '/score/sysScoreDetail/exportActWorkScoreRateList',
        method: 'POST',
        responseType: 'blob',
        data
    })
}
// 作品得分排名——列表
function selectActWorkScoreRankList(data) {
    return request({
        url: '/score/sysScoreDetail/selectActWorkScoreRankList',
        method: 'POST',
        data
    })
}
// 作品得分排名——导出
function exportActWorkScoreRankList(data) {
    return request({
        url: '/score/sysScoreDetail/exportActWorkScoreRankList',
        method: 'POST',
        responseType: 'blob',
        data
    })
}
// 获取作品投票结果分页列表
function selectActWorkTicketListPage(data) {
    return request({
        url: '/score/sysScoreDetail/selectActWorkTicketListPage',
        method: 'POST',
        data
    })
}
// 查看作品投票进度——列表
function selectActWorkTicketRateList(data) {
    return request({
        url: '/score/sysScoreDetail/selectActWorkTicketRateList',
        method: 'POST',
        data
    })
}
// 作品投票进度——导出
function exportActWorkTicketRateList(data) {
    return request({
        url: '/score/sysScoreDetail/exportActWorkTicketRateList',
        method: 'POST',
        responseType: 'blob',
        data
    })
}
// 作品票数排名——列表
function selectActWorkTicketRankList(data) {
    return request({
        url: '/score/sysScoreDetail/selectActWorkTicketRankList',
        method: 'POST',
        data
    })
}
// 作品票数排名——导出
function exportActWorkTicketRankList(data) {
    return request({
        url: '/score/sysScoreDetail/exportActWorkTicketRankList',
        method: 'POST',
        responseType: 'blob',
        data
    })
}
// 导出作品投票结果
function exportActWorkTicketList(data) {
    return request({
        url: '/score/sysScoreDetail/exportActWorkTicketList',
        method: 'POST',
        responseType: 'blob',
        data
    })
}

// 新增/修改评审用户
function sysReviewUserSaveAPI(data) {
    return request({
        url: '/score/sysUser/sysUserSave',
        method: 'POST',
        data
    })
}
// 所有评委票数恢复默认设置
function setAllUserTicketDefValAPI(data) {
    return request({
        url: '/score/sysUser/setAllUserTicketDefVal',
        method: 'POST',
        data
    })
}
// 下载评审系统模板文件
function downImportTemplateAPI(data) {
    return request({
        url: '/score/sysInfo/downImportTemplate',
        method: 'POST',
        responseType: 'blob',
        data
    })
}
// 批量创建评委账号
function sysReviewUserImportAPI(data, id) {
    return request({
        headers: { 'Content-Type': 'multipart/form-data' },
        url: '/score/sysUser/sysUserImport?sysId=' + id,
        method: 'POST',
        data
    })
}
// 评委分配作品数量列表
function selectReviewUserWorkCountList(data) {
    return request({
        url: '/score/sysUser/selectUserWorkCount',
        method: 'POST',
        data
    })
}
// 批量导入评委打分作品名单
function sysReviewScoreDetailImportAPI(data, id) {
    return request({
        headers: { 'Content-Type': 'multipart/form-data' },
        url: '/score/sysScoreDetail/sysScoreDetailImport?sysId=' + id,
        method: 'POST',
        data
    })
}
// 添加评委打分作品
function addReviewScoreWorkSaveAPI(data) {
    return request({
        url: '/score/sysScoreDetail/addScoreWorkSave',
        method: 'POST',
        data
    })
}
// 批量导入评委投票作品名单
function sysReviewScoreWorkImportAPI(data, id) {
    return request({
        headers: { 'Content-Type': 'multipart/form-data' },
        url: '/score/sysScoreWork/sysScoreWorkImport?sysId=' + id,
        method: 'POST',
        data
    })
}
// 投票系统获取已导入的作品列表
function selectSysScoreWorkListPage(data) {
    return request({
        url: '/score/sysScoreWork/selectListPage',
        method: 'POST',
        data
    })
}
// 投票系统手动导入作品
function addReviewVoteWorkSaveAPI(data) {
    return request({
        url: '/score/sysScoreWork/addScoreWorkSave',
        method: 'POST',
        data
    })
}
// 投票系统编辑作品状态
function editReviewVoteWorkSaveAPI(data) {
    return request({
        url: '/score/sysScoreWork/sysScoreWorkSave',
        method: 'POST',
        data
    })
}
// 下载作品名单
function exportReviewActWorkListAPI(data) {
    return request({
        url: '/score/actWork/exportActWorkList',
        method: 'POST',
        responseType: 'blob',
        data
    })
}
// 清除评委分配的作品
function clearReviewUserWorkAPI(data) {
    return request({
        url: '/score/sysScoreDetail/clearUserWork',
        method: 'POST',
        data
    })
}
// 清除评委的分数
function clearReviewUserWorkScoreAPI(data) {
    return request({
        url: '/score/sysScoreDetail/clearUserWorkScore',
        method: 'POST',
        data
    })
}
// 评审活动作品数据列表
function selectReviewScoreDetailListPage(data) {
    return request({
        url: '/score/sysScoreDetail/selectListPage',
        method: 'POST',
        data
    })
}
// 清除投票数据
function clearVoteWorkTicketAPI(data) {
    return request({
        url: '/score/sysScoreDetail/clearWorkTicket',
        method: 'POST',
        data
    })
}
// 清除投票作品
function clearSysVoteWorkAPI(data) {
    return request({
        url: '/score/sysScoreWork/clearSysWork',
        method: 'POST',
        data
    })
}
// 导出评审结果数据
function exportActSysWorkScoreListAPI(data) {
    return request({
        url: '/score/sysScoreDetail/exportActWorkScoreList',
        method: 'POST',
        responseType: 'blob',
        data
    })
}
// 评审活动作品数据编辑修改
function reviewScoreDetailSaveAPI(data) {
    return request({
        url: '/score/sysScoreDetail/sysScoreDetailSave',
        method: 'POST',
        data
    })
}
// 作品推选
// 作品推选保存
function actWorkElectSaveAPI(data) {
    return request({
        url: '/sys/actWork/actWorkSelectSave',
        method: 'POST',
        data
    })
}
// 推选页面获取活动作品列表
function selectWorkElectListPage(data) {
    return request({
        url: '/sys/actWork/selectWorkSelectListPage',
        method: 'POST',
        data
    })
}
// 获取当前推选系统数据统计情况
function getOrgWorksElectCountData(data) {
    return request({
        url: '/sys/actWork/getOrgWorkSelectCount',
        method: 'POST',
        data
    })
}
// 获取活动报名选送单位列表
function selectEnrollOrgListPage(data) {
    return request({
        url: '/sys/actEnroll/selectEnrollOrgList',
        method: 'POST',
        data
    })
}
// 单位推选情况
function getOrgWorkSelectCountList(data) {
    return request({
        url: '/sys/actWork/getOrgWorkSelectCountList',
        method: 'POST',
        data
    })
}
// 作品推选情况列表
function getAllWorkElectListPage(data) {
    return request({
        url: '/sys/actWork/getAllWorkSelectListPage',
        method: 'POST',
        data
    })
}
// 作品推选情况数据统计
function selectWorkElectCount(data) {
    return request({
        url: '/sys/actWork/selectWorkSelectCount',
        method: 'POST',
        data
    })
}
// 导出作品推选结果
function exportActWorkSelectList(data) {
    return request({
        url: '/score/sysScoreDetail/exportActWorkSelectList',
        method: 'POST',
        responseType: 'blob',
        data
    })
}

// 校验活动是否存在报名数据
function ifExistsActEnrollDataAPI(data) {
    return request({
        url: '/sys/actEnroll/ifExistsActEnrollData',
        method: 'POST',
        data
    })
}
// 校验活动是否存在作品数据
function ifExistsActWorkDataAPI(data) {
    return request({
        url: '/sys/actWork/ifExistsActWorkData',
        method: 'POST',
        data
    })
}

// 检查当前活动是否允许重新编号
function checkIsAllowCurrentActRecodeAPI(data) {
    return request({
        url: '/sys/actVoteContent/checkIsAllowCurrentActRecode',
        method: 'POST',
        data
    })
}

// 获取投票结果分页列表
function actVoteContentSelectListPageAPI(data) {
    return request({
        url: '/sys/actVoteContent/selectListPage',
        method: 'POST',
        data
    })
}
// 导出投票数据
function actVoteContentExportDataAPI(data) {
    return request({
        url: '/sys/actVoteContent/exportData',
        method: 'POST',
        responseType: 'blob',
        data
    })
}

// 检查内容是否已被投过票
function actVoteCheckContentAPI(data) {
    return request({
        url: '/sys/actVoteContent/checkContent',
        method: 'POST',
        data
    })
}

// 批量审核作品
function batchApproveWorkAPI(data) {
    return request({
        url: '/sys/actWork/batchApproveWork',
        method: 'POST',
        data
    })
}

// 获取活动奖项信息
function actAwardsInfoAPI(data) {
    return request({
        url: '/sys/actAwardsInfo/selectListPage',
        method: 'POST',
        data
    })
}

// 获取问卷列表
function selectQaLibListAPI(data) {
    return request({
        url: '/sys/actQuestion/selectListPage',
        method: 'POST',
        data
    })
}

// 获取机构模版、系统模版
function selectOrgOSysQaLibListAPI(data) {
    return request({
        url: '/sys/actQuestionLib/selectListPage',
        method: 'POST',
        data
    })
}

// 新增、删除问卷 【问卷列表】
function actQaLibrarySaveAPI(data) {
    return request({
        url: '/sys/actQuestion/actQuestionSave',
        method: 'POST',
        data
    })
}

// 新增、编辑、删除问卷 【机构模版】
function actQaOrgSaveAPI(data) {
    return request({
        url: '/sys/actQuestionLib/actQuestionLibSave',
        method: 'POST',
        data
    })
}

// 复制问卷 【机构模版】
function actQaOrgCopyAPI(data) {
    return request({
        url: '/sys/actQuestionLib/copy',
        method: 'POST',
        data
    })
}

// 根据id查询问卷 【问卷列表】
function getActQaListByIdAPI(data) {
    return request({
        url: '/sys/actQuestion/getActQuestion',
        method: 'POST',
        data
    })
}

// 根据id查询问卷 【机构模版】
function getActQaOrgByIdAPI(data) {
    return request({
        url: '/sys/actQuestionLib/getActQuestionLib',
        method: 'POST',
        data
    })
}

// 根据问卷code查询问卷
function getByQuestionCodeAPI(data) {
    return request({
        url: '/sys/actQuestion/getByQuestionCode',
        method: 'POST',
        data
    })
}

// 删除时检查是否有答题数据
function questionDelCheckByCodeAPI(data) {
    return request({
        url: '/sys/actQuestionAnswer/checkByCode',
        method: 'POST',
        data
    })
}

// 编辑时检查是否有答题数据
function questionEditCheckByCodeAPI(data) {
    return request({
        url: '/sys/actQuestionAnswer/checkByCodeInEdit',
        method: 'POST',
        data
    })
}

// 清除作答数据
function questionClearAnswerDataAPI(data) {
    return request({
        url: '/sys/actQuestion/clearAnswerData',
        method: 'POST',
        data
    })
}

// 机构模版问卷使用
function useQaTemplateQaAPI(data) {
    return request({
        url: '/sys/actQuestionLib/useTemplateQuestion',
        method: 'POST',
        data
    })
}

// 问卷列表复用
function reuseQaAPI(data) {
    return request({
        url: '/sys/actQuestion/copy',
        method: 'POST',
        data
    })
}

// 问卷列表使用
function qaListUseQaAPI(data) {
    return request({
        url: '/sys/actQuestion/actAndQuesAssociat',
        method: 'POST',
        data
    })
}

// 获取知识问答答题记录分页列表
function getQaRecordListAPI(data) {
    return request({
        url: '/sys/actQuestionAnswer/selectListPage',
        method: 'POST',
        data
    })
}

// 根据id查询知识问答答题记录
function getActQaRecordByIdAPI(data) {
    return request({
        url: '/sys/actQuestionAnswer/getActQuestionAnswer',
        method: 'POST',
        data
    })
}

// 知识问答答题记录保存（批卷）
function saveActQaRecordAPI(data) {
    return request({
        url: '/sys/actQuestionAnswer/actQuestionAnswerSave',
        method: 'POST',
        data
    })
}

// 获取知识问答答题报名数据分页列表
function selectQaEnrollListAPI(data) {
    return request({
        url: '/sys/actQuestionAnswer/selectEnrollListPage',
        method: 'POST',
        data
    })
}

// 导出答题报名数据
function exportQaEnrollDataAPI(data) {
    return request({
        url: '/sys/actQuestionAnswer/exportEnrollData',
        method: 'POST',
        responseType: 'blob',
        data
    })
}

// 获取活动问卷列表【问卷数据、答卷数据】
function getActQaListAPI(data) {
    return request({
        url: '/sys/actQuestion/getActQuestionList',
        method: 'POST',
        data
    })
}

// 导出答卷数据
function exportAnswerDataAPI(data) {
    return request({
        url: '/sys/actQuestionAnswer/exportAnswerData',
        method: 'POST',
        responseType: 'blob',
        data
    })
}

// 查看打卡记录列表
function selectActClockListAPI(data) {
    return request({
        url: '/sys/actClock/selectActClockListPage',
        method: 'POST',
        data
    })
}

// ===== 会议论坛 =====
// 获取会议论坛会议议程分页列表
function selectForumAgendaListPageAPI(data) {
    return request({
        url: '/sys/actForumAgenda/selectListPage',
        method: 'POST',
        data
    })
}

// 获取会议论坛议程会场类型分页列表
function selectForumVenueTypeListPageAPI(data) {
    return request({
        url: '/sys/actForumVenueType/selectListPage',
        method: 'POST',
        data
    })
}

// 获取会议论坛议程会场类型分页列表
function actForumVenueTypeSaveAPI(data) {
    return request({
        url: '/sys/actForumVenueType/actForumVenueTypeSave',
        method: 'POST',
        data
    })
}

// 获取会议论坛议程会场类型分页列表
function actForumAgendaSaveAPI(data) {
    return request({
        url: '/sys/actForumAgenda/actForumAgendaSave',
        method: 'POST',
        data
    })
}

// 通过xlsx批量导入议程
function actForumAgendaImportAPI(data, id) {
    return request({
        headers: { 'Content-Type': 'multipart/form-data' },
        url: '/sys/actForumAgenda/importByXlsx?actId=' + id,
        method: 'POST',
        data
    })
}

// 下载议程批量导入模板文件
function downForumAgendaImportTemplateAPI(data) {
    return request({
        url: '/sys/actForumAgenda/downImportTemplate',
        method: 'POST',
        responseType: 'blob',
        data
    })
}

// 获取会议论坛嘉宾信息分页列表
function selectForumGuestListPageAPI(data) {
    return request({
        url: '/sys/actForumGuest/selectListPage',
        method: 'POST',
        data
    })
}

// 会议论坛嘉宾信息保存(新增或修改)
function actForumGuestSaveAPI(data) {
    return request({
        url: '/sys/actForumGuest/actForumGuestSave',
        method: 'POST',
        data
    })
}

// 通过xlsx批量导入嘉宾信息
function actForumGuestImportAPI(data, id) {
    return request({
        headers: { 'Content-Type': 'multipart/form-data' },
        url: '/sys/actForumGuest/importByXlsx?actId=' + id,
        method: 'POST',
        data
    })
}

// 下载嘉宾信息批量导入模板文件
function downForumGuestImportTemplateAPI(data) {
    return request({
        url: '/sys/actForumGuest/downImportTemplate',
        method: 'POST',
        responseType: 'blob',
        data
    })
}

// 通过zip批量导入嘉宾头像
function actForumGuestAvatarImportAPI(data, id) {
    return request({
        headers: { 'Content-Type': 'multipart/form-data' },
        url: '/sys/actForumGuest/batchUploadGuestAvatars?actId=' + id,
        method: 'POST',
        data
    })
}

// 根据id查询会议详情
function getActForumAgendaAPI(data, id) {
    return request({
        url: '/sys/actForumAgenda/getActForumAgenda',
        method: 'POST',
        data
    })
}

// 获取会议模板列表
function selectActForumLibListAPI(data, id) {
    return request({
        url: '/sys/actForumPageLib/selectListPage',
        method: 'POST',
        data
    })
}

// 获取微信小程序二维码
function getWXQrcode(data) {
    return request({
        url: '/sys/wxMini/getQRCode',
        method: 'POST',
        responseType: 'blob',
        data
    })
}

// 外语活动
// 获取met课程类别列表
function selectCourseCategoryListAPI(data) {
    return request({
        baseURL: config.default.metUrl,
        url: '/sys/act/selectCourseCategoryList',
        method: 'POST',
        data: data
    })
}
// 获取met课时信息分页列表
function selectCourseLessonListAPI(data) {
    return request({
        baseURL: config.default.metUrl,
        url: '/sys/act/selectCourseLessonListPage',
        method: 'POST',
        data: data
    })
}

// 获取met课时句子信息列表
function selectCourseLessonsentenceListAPI(data) {
    return request({
        baseURL: config.default.metUrl,
        url: '/sys/act/selectCourseLessonSentenceList',
        method: 'POST',
        data: data
    })
}

// 获取met课程信息分页列表
function selectCourseListPageAPI(data) {
    return request({
        baseURL: config.default.metUrl,
        url: '/sys/act/selectCourseListPage',
        method: 'POST',
        data: data
    })
}

// 根据课时id同步信息
function syncSentenceInfoByLessonIdAPI(data) {
    return request({
        url: '/sys/actLangLevel/syncMetContent',
        method: 'POST',
        data: data
    })
}

// 一键清空关卡设置
function oneClearAllLevelAPI(data) {
    return request({
        url: '/sys/actLangLevel/oneClickClear',
        method: 'POST',
        data: data
    })
}

// 外语活动预览课时
function getSentenceListAPI(data) {
    return request({
        url: '/sys/metCourseLessonSentence/selectListPage',
        method: 'POST',
        data: data
    })
}

// 外语活动关卡内容排序调整
function sortExchangeAPI(data) {
    return request({
        url: '/sys/actLangLevel/sortExchange',
        method: 'POST',
        data: data
    })
}
//外语活动关卡内容添加
function actLangLevelAddAPI(data) {
    return request({
        url: '/sys/actLangLevel/actLangLevelAdd',
        method: 'POST',
        data: data
    })
}
//外语活动关卡内容保存(修改)
function actLangLevelSaveAPI(data) {
    return request({
        url: '/sys/actLangLevel/actLangLevelSave',
        method: 'POST',
        data: data
    })
}

// 外语活动--关卡内容组别 统一设置/单独设置切换
function groupContentSwitchAPI(data) {
    return request({
        url: '/sys/actLangLevel/groupContentSwitch',
        method: 'POST',
        data: data
    })
}
// 根据ID查询外语活动关卡内容
function getActLangLevelAPI(data) {
    return request({
        url: '/sys/actLangLevel/getActLangLevel',
        method: 'POST',
        data: data
    })
}

// 获取外语活动关卡内容分页列表
function selectActLangListAPI(data) {
    return request({
        url: '/sys/actLangLevel/selectListPage',
        method: 'POST',
        data: data
    })
}

// 获取晋级选手名单分页列表
function selectActEnrollListAPI(data) {
    return request({
        url: '/sys/actEnroll/selectListPage',
        method: 'POST',
        data: data
    })
}

// 保存晋级选手名单
function batchAddQualifiedUserAPI(data) {
    return request({
        url: '/sys/actEnroll/batchAddQualifiedUser',
        method: 'POST',
        data: data
    })
}

// 批量删除晋级选手名单
function batchDelQualifiedUserAPI(data) {
    return request({
        url: '/sys/actEnroll/qualifiedUserBatchDel',
        method: 'POST',
        data: data
    })
}

// 清空晋级选手名单
function clearAllQualifiedUserAPI(data) {
    return request({
        url: '/sys/actEnroll/qualifiedUserOneClickClear',
        method: 'POST',
        data: data
    })
}

// 晋级选手名单下载
function downQualifiedUserImportTemplateAPI(data) {
    return request({
        url: '/sys/actEnroll/downQualifiedUserImportTemplate',
        method: 'POST',
        responseType: 'blob',
        data: data
    })
}

// 导入晋级选手名单
function importQualifiedUserByXlsxAPI(data, id) {
    return request({
        headers: { 'Content-Type': 'multipart/form-data' },
        url: '/sys/actEnroll/importQualifiedUserByXlsx',
        method: 'POST',
        data
    })
}

// 查看关联活动列表
function getAssTreeListAPI(data) {
    return request({
        url: '/sys/act/getAssTree',
        method: 'POST',
        data
    })
}

// 活动列表数据导出
function actExportData(data) {
    return request({
        url: '/sys/act/exportData',
        method: 'POST',
        responseType: 'blob',
        data: data
    })
}

module.exports = {
    getAct,
    saveAct,
    selectActList,
    getActConfig,
    saveActConfig,
    actCustomOrderSave,
    selectCustomListPage,
    actCustomOrderDelete,
    getActCustomOrder,
    checkActUrlAPI,
    selectApproveDataListPage,
    selectActWorkListPage,
    collectActWorkSave,
    collectActWorkExportData,
    getCollectActWorkData,
    getActClock,
    selectWorkAnnexListPage,
    sysInfoSaveAPI,
    selectSysScoreInfoListPage,
    getSysInfoDataAPI,
    selectSysUserListPage,
    selectActWorkScoreListPage,
    selectActWorkScoreRateList,
    exportActWorkScoreRateList,
    selectActWorkScoreRankList,
    exportActWorkScoreRankList,
    selectActWorkTicketListPage,
    selectActWorkTicketRateList,
    exportActWorkTicketRateList,
    selectActWorkTicketRankList,
    exportActWorkTicketRankList,
    exportActWorkTicketList,
    sysReviewUserSaveAPI,
    setAllUserTicketDefValAPI,
    downImportTemplateAPI,
    sysReviewUserImportAPI,
    selectReviewUserWorkCountList,
    sysReviewScoreDetailImportAPI,
    sysReviewScoreWorkImportAPI,
    addReviewScoreWorkSaveAPI,
    exportReviewActWorkListAPI,
    clearReviewUserWorkAPI,
    clearReviewUserWorkScoreAPI,
    selectReviewScoreDetailListPage,
    selectSysScoreWorkListPage,
    addReviewVoteWorkSaveAPI,
    editReviewVoteWorkSaveAPI,
    clearVoteWorkTicketAPI,
    clearSysVoteWorkAPI,
    exportActSysWorkScoreListAPI,
    reviewScoreDetailSaveAPI,
    actWorkElectSaveAPI,
    selectWorkElectListPage,
    getOrgWorksElectCountData,
    selectEnrollOrgListPage,
    getOrgWorkSelectCountList,
    getAllWorkElectListPage,
    selectWorkElectCount,
    exportActWorkSelectList,
    ifExistsActEnrollDataAPI,
    ifExistsActWorkDataAPI,
    checkIsAllowCurrentActRecodeAPI,
    actVoteContentSelectListPageAPI,
    actVoteContentExportDataAPI,
    actVoteCheckContentAPI,
    batchApproveWorkAPI,
    actAwardsInfoAPI,
    selectQaLibListAPI,
    actQaLibrarySaveAPI,
    getByQuestionCodeAPI,
    questionDelCheckByCodeAPI,
    questionEditCheckByCodeAPI,
    questionClearAnswerDataAPI,
    selectOrgOSysQaLibListAPI,
    actQaOrgSaveAPI,
    actQaOrgCopyAPI,
    getActQaListByIdAPI,
    getActQaOrgByIdAPI,
    useQaTemplateQaAPI,
    reuseQaAPI,
    qaListUseQaAPI,
    getQaRecordListAPI,
    getActQaRecordByIdAPI,
    saveActQaRecordAPI,
    selectQaEnrollListAPI,
    exportQaEnrollDataAPI,
    getActQaListAPI,
    exportAnswerDataAPI,
    selectActClockListAPI,
    getWXQrcode,

    // 会议论坛
    selectForumAgendaListPageAPI,
    selectForumVenueTypeListPageAPI,
    actForumVenueTypeSaveAPI,
    actForumAgendaSaveAPI,
    actForumAgendaImportAPI,
    downForumAgendaImportTemplateAPI,
    selectForumGuestListPageAPI,
    actForumGuestSaveAPI,
    actForumGuestImportAPI,
    downForumGuestImportTemplateAPI,
    actForumGuestAvatarImportAPI,
    getActForumAgendaAPI,
    selectActForumLibListAPI,
    // 外语活动
    selectCourseCategoryListAPI,
    selectCourseLessonListAPI,
    syncSentenceInfoByLessonIdAPI,
    selectCourseListPageAPI,
    selectCourseLessonsentenceListAPI,
    oneClearAllLevelAPI,
    sortExchangeAPI,
    actLangLevelAddAPI,
    groupContentSwitchAPI,
    actLangLevelSaveAPI,
    getActLangLevelAPI,
    selectActLangListAPI,
    getSentenceListAPI,
    // 关联活动
    selectActEnrollListAPI,
    batchAddQualifiedUserAPI,
    batchDelQualifiedUserAPI,
    clearAllQualifiedUserAPI,
    downQualifiedUserImportTemplateAPI,
    importQualifiedUserByXlsxAPI,
    getAssTreeListAPI,

    actExportData
}
