import { request } from './request'

// 活动数据
// 获取活动数据列表
function selectActDataListPage(data) {
    return request({
        url: '/sys/act/selectActDataListPage',
        method: 'POST',
        data
    })
}
// 报名数据
function selectActEnrollListPage(data) {
    return request({
        url: '/sys/actEnroll/selectListPage',
        method: 'POST',
        data
    })
}
// 作品数据
function selectActWorkListPage(data) {
    return request({
        url: '/sys/actWork/selectListPage',
        method: 'POST',
        data
    })
}
// 判断作品数据是否显示投票数量
function getActStatusInfoAPI(data) {
    return request({
        url: '/sys/act/getActStatusInfo',
        method: 'POST',
        data
    })
}
// 活动组别
function selectActGroupListPage(data) {
    return request({
        url: '/sys/actGroup/selectListPage',
        method: 'POST',
        data
    })
}
// 活动类别
function selectActWorkTypeListPage(data) {
    return request({
        url: '/sys/actWorkType/selectListPage',
        method: 'POST',
        data
    })
}
// 活动主题
function selectActThemeListPage(data) {
    return request({
        url: '/sys/actTheme/selectListPage',
        method: 'POST',
        data
    })
}
// 活动报名数据导出
function actEnrollExportData(data) {
    return request({
        url: '/sys/actEnroll/exportData',
        method: 'POST',
        responseType: 'blob',
        data: data
    })
}
// 活动作品数据导出
function actWorkExportData(data) {
    return request({
        url: '/sys/actWork/exportData',
        method: 'POST',
        responseType: 'blob',
        data: data
    })
}
// 机构报名数据
function selectActOrgEnrollListPage(data) {
    return request({
        url: '/sys/act/selectActOrgRankListPage',
        method: 'POST',
        data
    })
}
// 机构报名数据导出
function actOrgRankExportData(data) {
    return request({
        url: '/sys/act/exportActOrgRankData',
        method: 'POST',
        responseType: 'blob',
        data: data
    })
}
// 打卡数据
function selectActClockListPage(data) {
    return request({
        url: '/sys/actClock/selectListPage',
        method: 'POST',
        data
    })
}
// 打卡数据导出
function actClockListExportData(data) {
    return request({
        url: '/sys/actClock/exportData',
        method: 'POST',
        responseType: 'blob',
        data: data
    })
}
// 选手数据导出
function actUserClockDataExportData(data) {
    return request({
        url: '/sys/actClock/exportUserClockData',
        method: 'POST',
        responseType: 'blob',
        data: data
    })
}
// 外语活动闯关数据
function selectChallengeDataListAPI(data) {
    return request({
        url: '/sys/actLangLevelSpoke/selectUserChallengeDataListPage',
        method: 'POST',
        data
    })
}
// 外语活动闯关数据 -导出
function exportChallengeDataAPI(data) {
    return request({
        url: '/sys/actLangLevelSpoke/exportUserChallengeData',
        method: 'POST',
        responseType: 'blob',
        data: data
    })
}

// 外语活动关卡数量
function getLevelCountAPI(data) {
    return request({
        url: '/sys/actLangLevel/selectCount',
        method: 'POST',
        data
    })
}

// 外语活动关卡详情
function getLevelSpokeDetailAPI(data) {
    return request({
        url: '/sys/actLangLevelSpokeDetail/selectListPage',
        method: 'POST',
        data
    })
}

// 外语活动-报名数据成绩详情
function getLevelScoreDetailAPI(data) {
    return request({
        url: '/sys/actLangLevelSpoke/selectUserChallengeDataListPage',
        method: 'POST',
        data
    })
}

// 用户数据
// 获取用户统计数据
function getWebUserCountData(data) {
    return request({
        url: '/sys/userChart/getWebUserCountData',
        method: 'POST',
        data
    })
}
// 获取官网用户性别分布统计数据
function getWebUseSexCountList(data) {
    return request({
        url: '/sys/userChart/getWebUseSexCountList',
        method: 'POST',
        data
    })
}
// 获取官网用户数据（按天、按月）
function getWebUserTrend(data) {
    return request({
        url: '/sys/userChart/getWebUserTrend',
        method: 'POST',
        data
    })
}
// 导出官网用户数据
function exportWebUserTrend(data) {
    return request({
        url: '/sys/userChart/exportWebUserTrend',
        method: 'POST',
        responseType: 'blob',
        data
    })
}
// 获取新增机构用户数据（按天、按月）
function getOrgUserTrend(data) {
    return request({
        url: '/sys/orgChart/getOrgUserTrend',
        method: 'POST',
        data
    })
}
// 导出新增机构用户数据
function exportOrgUserTrend(data) {
    return request({
        url: '/sys/orgChart/exportOrgUserTrend',
        method: 'POST',
        responseType: 'blob',
        data
    })
}
// 获取访问数据（按天、按月）
function getVisitTrend(data) {
    return request({
        url: '/sys/visitChart/getVisitTrend',
        method: 'POST',
        data
    })
}
// 导出访问数据
function exportVisitTrend(data) {
    return request({
        url: '/sys/visitChart/exportVisitTrend',
        method: 'POST',
        responseType: 'blob',
        data
    })
}
// 获取官网用户性别趋势统计数据
function getWebUserSexTrend(data) {
    return request({
        url: '/sys/userChart/getWebUserSexTrend',
        method: 'POST',
        data
    })
}
// 获取官网用户年龄分布统计数据
function getWebUseAgeCountList(data) {
    return request({
        url: '/sys/userChart/getWebUseAgeCountList',
        method: 'POST',
        data
    })
}
// 获取官网用户年龄趋势统计数据
function getWebUserAgeTrend(data) {
    return request({
        url: '/sys/userChart/getWebUserAgeTrend',
        method: 'POST',
        data
    })
}
// 获取官网用户地域分布统计数据
function selectWebUserProvinceCount(data) {
    return request({
        url: '/sys/userChart/selectWebUserProvinceCount',
        method: 'POST',
        data
    })
}
// 获取机构统计数据
function getOrgCountData(data) {
    return request({
        url: '/sys/orgChart/getOrgCountData',
        method: 'POST',
        data
    })
}
// 获取活动创建类型分布统计数据
function getActTypeCount(data) {
    return request({
        url: '/sys/orgChart/getActTypeCount',
        method: 'POST',
        data
    })
}
// 获取访问统计数据
function getVisitCountData(data) {
    return request({
        url: '/sys/visitChart/getVisitCountData',
        method: 'POST',
        data
    })
}
// 获取实时访问数据
function getVisitHourTrend(data) {
    return request({
        url: '/sys/visitChart/getVisitHourTrend',
        method: 'POST',
        data
    })
}
// 获取浏览器分布数据
function getVisitTerminalCount(data) {
    return request({
        url: '/sys/visitChart/getVisitTerminalCount',
        method: 'POST',
        data
    })
}
// 获取终端分布数据
function getVisitOSCount(data) {
    return request({
        url: '/sys/visitChart/getVisitOSCount',
        method: 'POST',
        data
    })
}
// 查询会议论坛核心数据
function selectCoreDataAPI(data) {
    return request({
        url: '/sys/actForumFunVisitData/selectCoreData',
        method: 'POST',
        data
    })
}
//导出会议议程访问数据
function exportAgendaDataAPI(data) {
    return request({
        url: '/sys/actForumFunVisitData/exportAgendaVisitDataList',
        method: 'POST',
        responseType: 'blob',
        data
    })
}
//导出会议功能使用数据
function exportFunUseDataAPI(data) {
    return request({
        url: '/sys/actForumFunVisitData/exportFunUseDataList',
        method: 'POST',
        responseType: 'blob',
        data
    })
}
//导出会议嘉宾访问数据
function exportGuestDataAPI(data) {
    return request({
        url: '/sys/actForumFunVisitData/exportGuestVisitDataList',
        method: 'POST',
        responseType: 'blob',
        data
    })
}
//查询会议议程访问数据
function selectAgendaDataAPI(data) {
    return request({
        url: '/sys/actForumFunVisitData/selectAgendaVisitDataList',
        method: 'POST',
        data
    })
}
//查询会议嘉宾访问数据
function selectGuestVisitAPI(data) {
    return request({
        url: '/sys/actForumFunVisitData/selectGuestVisitDataList',
        method: 'POST',
        data
    })
}
//查询会议功能使用数据
function selectFunUseDataAPI(data) {
    return request({
        url: '/sys/actForumFunVisitData/selectFunUseDataList',
        method: 'POST',
        data
    })
}

//查询推荐资源数据统计
function countAccessDataAPI(data) {
    return request({
        url: '/sys/actRecommendContentVisitData/countAccessData',
        method: 'POST',
        data
    })
}
//导出推荐资源数据统计
function exportCountAccessAPI(data) {
    return request({
        url: '/sys/actRecommendContentVisitData/exportCountAccessData',
        method: 'POST',
        responseType: 'blob',
        data
    })
}

//查询推荐资源数据统计列表
function countResourceClickAPI(data) {
    return request({
        url: '/sys/actRecommendContentVisitData/countResourceClickData',
        method: 'POST',
        data
    })
}
//导出推荐资源数据统计列表
function exportCountResourceAPI(data) {
    return request({
        url: '/sys/actRecommendContentVisitData/exportCountResourceClickData',
        method: 'POST',
        responseType: 'blob',
        data
    })
}

export default {
    selectActDataListPage,
    selectActEnrollListPage,
    selectActWorkListPage,
    getActStatusInfoAPI,
    selectActGroupListPage,
    selectActWorkTypeListPage,
    selectActThemeListPage,
    selectActOrgEnrollListPage,
    selectActClockListPage,
    actOrgRankExportData,
    actClockListExportData,
    actUserClockDataExportData,
    actEnrollExportData,
    actWorkExportData,
    getWebUserCountData,
    getWebUseSexCountList,
    getWebUserTrend,
    exportWebUserTrend,
    getOrgUserTrend,
    exportOrgUserTrend,
    getVisitTrend,
    exportVisitTrend,
    getWebUserSexTrend,
    getWebUseAgeCountList,
    getWebUserAgeTrend,
    selectWebUserProvinceCount,
    getOrgCountData,
    getActTypeCount,
    getVisitCountData,
    getVisitHourTrend,
    getVisitTerminalCount,
    getVisitOSCount,
    selectCoreDataAPI,
    selectGuestVisitAPI,
    selectFunUseDataAPI,
    selectAgendaDataAPI,
    exportFunUseDataAPI,
    exportGuestDataAPI,
    exportAgendaDataAPI,
    countAccessDataAPI,
    exportCountAccessAPI,
    countResourceClickAPI,
    exportCountResourceAPI,
    exportChallengeDataAPI,
    selectChallengeDataListAPI,
    getLevelScoreDetailAPI,
    getLevelSpokeDetailAPI,
    getLevelCountAPI
}
